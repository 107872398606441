import "vuestic-ui/css";
import "./assets/main.scss";
import App from "./App.vue";
import router from "./router";
import { createApp } from "vue";
import { createPinia } from "pinia";
import { vuestic } from "./plugins/vuestic";
import FontAwesomeIcon from "./plugins/fontawesome";
import i18n from "./plugins/i18n";
import { createManager } from "@vue-youtube/core";
import Particles from "@tsparticles/vue3";
import { loadFull } from "tsparticles";
import "material-symbols/rounded.css";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import VueGtag from "vue-gtag-next";
import { VueCookieNext } from "vue-cookie-next";

const app = createApp(App);
const pinia = createPinia();

app.component("fa-icon", FontAwesomeIcon);
app.component("qr-code", VueQrcode);
app.use(Particles, {
  init: async (engine) => {
    await loadFull(engine);
  },
});
app.use(createManager());
app.use(pinia);
app.use(router);
app.use(i18n);
app.use(vuestic);
app.use(VueCookieNext);
app.use(VueGtag, {
  property: { id: "GTM-57KLV8R" },
  router,
  isEnabled: false,
});

app.mount("#app");
document.title = import.meta.env.VITE_TITLE;

<template>
    <div class="eki-title" :class="{
        visible: playerX.showTitle && playerX.currentSong && playerX.playing
    }">
        <div class="song-img" v-if="playerX.currentSong">
            <va-image class="img" :src="playerX.currentSong.gen.img " lazy></va-image>
        </div>
        <div class="title" v-if="playerX.currentSong">{{ playerX.currentSong.title }}</div>
        <div class="artists" v-if="playerX.currentSong">{{ playerX.currentSong.art.name }} - {{ playerX.currentSong.gen.name }}</div>
    </div>

</template>

<script setup>
import { usePlayerStore } from "@/stores/player";

const playerX = usePlayerStore();

</script>

<style scoped lang="scss" src="./title.scss"></style>